<template>
    <div>
        <div v-if="sondage">
            <div class="fixed top-0 left-0 right-0 bottom-0" style="z-index: -1">
                <div class="h-[50vh] absolute top-0 left-0 right-0 bottom-0" style="z-index: -2; background-size: cover; background-position: center center;" :style="'background-image: url(' + sondage.image + ')'"></div>
                <div class="h-[50vh] asbolute top-0 left-0 right-0 bottom-0 bg-slate-700/50" style="z-index: -1;"></div>
            </div>
            <div class="max-w-[700px] mx-auto min-h-screen bg-white mt-32 p-10 rounded border shadow-lg" v-if="sondage">
                <div v-if="validatedForm" class="bg-green-600 text-white p-5 rounded-lg font-bold flex gap-4 items-center">
                    <i class="fas fa-check-circle text-3xl"></i>
                    <div>
                        <h2 class="font-bold text-xl">Vos réponses ont été enregistrées</h2>
                        <div class="font-light text-sm mt-1">Merci d'avoir contribué à ce sondage.</div>
                    </div>
                </div>
                <template v-if="!validatedForm">
                    <h2 class="text-4xl font-semibold mb-5">{{ sondage.nom }}</h2>
                    <p class="mb-3 text-lg font-light" v-html="sondage.description"></p>
                    <div class="flex gap-10 mb-10 font-light text-sm text-gray-700 border-t pt-4">
                        <div><i class="fas fa-list mr-1"></i> {{ nbQuestions }} questions</div>
                        <div v-if="sondage.temps"><i class="far fa-clock mr-1"></i> Temps de réponse estimé: {{ sondage.temps }} minutes</div>
                    </div>
                    <div v-for="(step, stepIndex) in sondage.form" :key="'step-' + stepIndex" class="bg-white mb-20">
                        <div class="text-green-600 text-2xl border-b-2 pb-4 border-green-600 font-black mb-8">{{ step.title }}</div>
                        
                        <div v-for="(inp, inpIndex) in step.inputs" :key="'step-' + stepIndex + '-inp-' + inpIndex">
                            <div class="w-full items-center mb-10">
                                <label class="text-sm text-gray-800 my-3 block font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    {{ inp.label }}
                                </label>
                                <template v-if="inp.type == 'text'">
                                    <input v-model="form[inp.name]" @input="updateInp(inp.name, false)" type="text" class="h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50" />
                                </template>
                                <template v-if="inp.type == 'date'">
                                    <input v-model="form[inp.name]" @input="updateInp(inp.name, false)" type="date" class="h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50" />
                                </template>
                                <template v-if="inp.type == 'checkbox'">
                                    <div v-for="(option, optionIndex) in inp.values" :key="'step-' + stepIndex + '-inp-' + inpIndex + '-option-' + optionIndex">
                                        <div class="flex items-center gap-4 mb-2">
                                            <input v-model="form[inp.name]" @input="updateInp(inp.name, true)" :value="option.value" type="checkbox" class="rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50" :id="optionIndex + '-' + inp.name" />
                                            <label class="font-light text-slate-500" :for="optionIndex + '-' + inp.name">{{ option.label }}</label>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="inp.type == 'radio'">
                                    <div v-for="(option, optionIndex) in inp.values" :key="'step-' + stepIndex + '-inp-' + inpIndex + '-option-' + optionIndex">
                                        <div class="flex items-center gap-4 mb-2">
                                            <input v-model="form[inp.name]" @input="updateInp(inp.name, false)" :value="option.value" type="radio" class="rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50" :id="optionIndex + '-' + inp.name" />
                                            <label class="font-light text-slate-500" :for="optionIndex + '-' + inp.name">{{ option.label }}</label>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="inp.type == 'select'">
                                    <select v-model="form[inp.name]" @input="updateInp(inp.name, false)" class="h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50">
                                        <option v-for="(option, optionIndex) in inp.values" :key="'step-' + stepIndex + '-inp-' + inpIndex + '-option-' + optionIndex" :value="option.value">{{ option.label }}</option>
                                    </select>
                                </template>
                                <template v-if="inp.type == 'textarea'">
                                    <textarea v-model="form[inp.name]" @input="updateInp(inp.name, false)" class="w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50" rows="4"></textarea>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mb-10">
                        <button @click="validForm" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white shadow-lg">Enregistrer <i class="fas fa-check ml-1"></i></button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sondage: null,
            form: {},
            timerSendInp: null,
            validatedForm: false
        }
    },
    computed: {
        nbQuestions() {
            let nbQuestions = 0
            if (this.sondage) {
                this.sondage.form.forEach((item) => {
                    nbQuestions += item.inputs.length
                })
            }
            return nbQuestions
        },
        timeEstimation() {
            return Math.floor((30 + (this.nbQuestions * 20)) / 60)
        }
    },
    methods: {
        async getSondage() {
            let response = await this.$request.post('/sondage/get', {
                token: this.$route.params.token
            })
            this.sondage = response.data.api.sondage
            this.form = {}
            if (this.sondage.valid) {
                this.validatedForm = true
            } else {
                response.data.api.sondage.form.forEach((form) => {
                    form.inputs.forEach((inp) => {
                        if (inp.type == 'checkbox') {
                            this.form[inp.name] = []
                        } else if (inp.type == 'radio') {
                            this.form[inp.name] = "0"
                        } else {
                            this.form[inp.name] = null
                        }
                    })
                })
                let answers = response.data.api.anwsers
                for (let index in answers) {
                    if (this.getInputType(index) == 'checkbox') {
                        this.form[index] = JSON.parse(answers[index])
                    } else {
                        this.form[index] = answers[index]
                    }
                }
            }
        },

        getInputType(name) {
            let endType = null
            this.sondage.form.forEach((form) => {
                form.inputs.forEach((inp) => {
                    if (inp.name == name) {
                        endType = inp.type
                    }
                })
            })
            return endType
        },

        updateInp(name, jsonEncode) {
            clearTimeout(this.timerSendInp)
            this.timerSendInp = setTimeout(async () => {
                await this.$request.post('/sondage/edit-add-anwser', {
                    name: name,
                    value: jsonEncode ? JSON.stringify(this.form[name]) : this.form[name],
                    token: this.$route.params.token
                })
            }, 400)
        },

        async validForm() {
            let response = await this.$request.post('/sondage/valid', {
                token: this.$route.params.token
            })
            if (response.data.api == 1) {
                this.validatedForm = true
            }
        }
    },
    mounted () {
        this.getSondage();
    },
}
</script>

<style lang="scss" scoped>

</style>