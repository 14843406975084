import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Sondage from '../views/Sondage.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'home', component: Home},
  {path: '/sondage/:token', name: 'sondage', component: Sondage},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
